import React, { useContext } from 'react'
import PageContext from '@context'
import { Button, LinearProgress, Box } from '@material-ui/core'
import Field from '../field'
import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import isUX1Country from '@helpers/ux1-country'
import { H4, Text, TextSm, MarkdownHandler } from '@system'
import classNames from 'classnames'
import get from 'lodash/get'
import { monochrome } from '@helpers/palette'
import Icon from '@system/icon'
import useStringTranslation from '@hooks/use-string-translation'
import { privacyTextKey } from '@variables/static-strings'

const useStyles = makeStyles((theme) => ({
  singleColumnFormSubmitButton: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& button': {
      borderRadius: '0',
      fontSize: '18px',
      height: '60px',
    },
  },
  widthControl: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
  },
  outerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  quizLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  quizFieldStyles: {
    '& p': {
      width: '100%',
      textAlign: 'left',
    },
    '& div': {
      width: '100%',
    },
  },
  quizSkipButton: {
    padding: 0,
    minWidth: 0,
  },
  multiOptionsFieldWidth: {
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: '10px',
    },
  },
  contained: {
    color: (props) => monochrome(props.color?.color),
    backgroundColor: (props) => monochrome(props.bgColor?.color),
    padding: (props) => props.size === 'medium' && '12px 18px',
    boxShadow: 'none',
    '&:hover': {
      color: (props) => monochrome(props.color?.color),
      backgroundColor: (props) => monochrome(props.bgColor?.color),
      boxShadow: 'none',
    },
  },
  icon: {
    fill: (props) =>
      props.bgColor && props.bgColor.color.color === '#FFFFFF'
        ? theme.palette.text.primary
        : theme.palette.text.white,
  },
}))

const SafeCheckboxes = (fieldData) => {
  const safeText = useStringTranslation(fieldData.text || '')
  return {
    ...fieldData,
    text: safeText,
  }
}

const SingleColumnForm = ({
  isSubmitting,
  values,
  localizedStrings,
  variant,
  ...props
}) => {
  const styles = props.formProps.styleProps
  const {
    title,
    description,
    marketoId,
    submitButton,
    titleIsH1ForPage,
    isNotMarketableForm,
  } = props.formProps.blokProps
  const {
    fieldsToRender,
    isMultiSelect,
    isUX1CountryField,
    fetchCheckboxLabel,
    locale,
    isSubmitButtonVisible,
    isQuiz,
    quizCallback,
    submitButtonText,
    isModalContent,
  } = props.formProps

  const submitButtonProps = get(props.formProps, 'submitButton[0]', {})

  const PRIVACY_TEXT = useStringTranslation(privacyTextKey)

  const classes = useStyles(submitButtonProps)
  const context = useContext(PageContext)

  const submitButtonIcon = submitButtonProps.icon && (
    <Icon>{submitButtonProps.icon}</Icon>
  )

  const pageDataProduct =
    !!context.children[1].props.blok &&
    context.children[1].props.blok.dataProduct

  return (
    <Box className={classNames({ [classes.widthControl]: !!isQuiz })}>
      <Box
        className={classNames({
          [classes.outerContainer]: isMultiSelect,
          [classes.quizLayout]: isQuiz,
        })}
      >
        <Box
          className={classNames({
            [classes.multiOptionsFieldWidth]: isMultiSelect,
          })}
        >
          {!!title && !isModalContent && (
            <H4
              component={titleIsH1ForPage ? 'h1' : 'h4'}
              display="block"
              className={styles.title}
            >
              {title}
            </H4>
          )}
          {!!description && (
            <Text display="block" className={styles.description}>
              {description}
            </Text>
          )}
          <Box>
            {fieldsToRender &&
              fieldsToRender.map((fieldData) => {
                return (
                  <Box key={fieldData._uid} className={classes.quizFieldStyles}>
                    {(isMultiSelect || isQuiz) && (
                      <Text className={styles.heading}>
                        {fieldData.fieldName}
                      </Text>
                    )}
                    {fieldData.component !== 'marketoFormMultiSelect' &&
                    fieldData.component !== 'marketoFormField'
                      ? (fieldData.fieldId === 'optinDataCollection' ||
                          fieldData.fieldId ===
                            'optinMarketingCommunications') &&
                        !isNotMarketableForm
                        ? isUX1CountryField(fieldData) &&
                          renderBlok(SafeCheckboxes(fieldData), {
                            variant: variant,
                          })
                        : null
                      : fieldData.component !== 'marketoFormMultiSelect' && (
                          <Field
                            {...fieldData}
                            shouldShowPlaceholder={!isMultiSelect && !isQuiz}
                          />
                        )}
                  </Box>
                )
              })}
          </Box>
        </Box>
        {fieldsToRender &&
          fieldsToRender.map(
            (fieldData) =>
              fieldData.component === 'marketoFormMultiSelect' && (
                <Box className={classes.multiOptionsFieldWidth}>
                  {fetchCheckboxLabel(
                    fieldData.fieldOptions
                      ? fieldData.fieldOptions[0].options
                      : fieldData.fieldOptions
                  )}

                  {renderBlok(fieldData)}
                </Box>
              )
          )}
      </Box>
      {isSubmitting && <LinearProgress />}
      <Box
        className={classNames(classes.singleColumnFormSubmitButton, {
          [classes.quizFieldWidth]: !!isQuiz,
        })}
      >
        <Button
          id={`submit-button-${marketoId}`}
          marketo-id={marketoId}
          data-analytics={submitButtonProps.dataAnalytics || 'Form Submission'}
          data-product={
            submitButtonProps.dataProduct || pageDataProduct || 'generic'
          }
          variant="contained"
          color={submitButton.buttonColor || 'primary'}
          classes={{
            contained: classes.contained,
          }}
          disabled={
            (isUX1Country(locale)
              ? false
              : isMultiSelect || isSubmitButtonVisible
              ? false
              : !values.optinDataCollection) || isSubmitting
          }
          type="submit"
          fullWidth={submitButtonProps.fullWidth}
          endIcon={submitButtonIcon}
          download
        >
          {localizedStrings[
            submitButtonText?.toLowerCase() ||
              submitButtonProps.text?.toLowerCase() ||
              'submit'
          ] ||
            submitButtonText ||
            submitButtonProps.text ||
            'Submit'}
        </Button>
        {!!isQuiz && context.isInEditor && (
          <Button
            onClick={quizCallback}
            color="primary"
            className={classes.quizSkipButton}
          >
            {localizedStrings['skip'] || 'Skip'}
          </Button>
        )}
      </Box>
      {isUX1Country(locale) && !isNotMarketableForm && (
        <TextSm
          display="block"
          component="div"
          className={classNames(styles.privacyText, {
            [classes.quizPrivacyWidth]: !!isQuiz,
          })}
        >
          {<MarkdownHandler>{PRIVACY_TEXT}</MarkdownHandler>}
        </TextSm>
      )}
    </Box>
  )
}

export default SingleColumnForm
