import React from 'react'
import { Field as FormikField } from 'formik'
import { InputField } from '@system'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'
import { emailRegex } from '@helpers/validation'

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: 'red',
  },
}))

const validateInputField = (value, fieldId, isRequired) => {
  let error
  if (!value && isRequired) {
    error = 'Field Required'
  } else if (fieldId === 'Email') {
    if (!emailRegex.test(value)) {
      error = 'Invalid email address'
    }
  }
  return error
}

const CustomInputComponent = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const classes = useStyles()
  const error = touched[field.name] && errors[field.name] && props.required
  return (
    <>
      <InputField
        type={props.type}
        placeholder={props.placeholder}
        required={props.required}
        index={field.name}
        error={error ? true : false}
        {...field}
        {...props}
      />
      {error && (
        <Box className={classes.errorMessage}>{errors[field.name]}</Box>
      )}
    </>
  )
}

const Field = (props) => {
  const {
    fieldId,
    fieldName,
    fieldType,
    fieldOptions,
    placeHolder,
    required,
    margin,
    shouldShowPlaceholder = true,
  } = props
  return (
    <FormikField
      name={fieldId.trim()}
      type={fieldType || 'text'}
      placeholder={
        shouldShowPlaceholder ? placeHolder || fieldName || 'text' : null
      }
      options={fieldOptions}
      required={required}
      component={CustomInputComponent}
      validate={(e) => validateInputField(e, fieldId.trim(), required)}
      margin={margin || 'normal'}
    />
  )
}

export default Field
